// import React from "react";
// import s from "./style.module.css";
// import { motion } from "framer-motion";

const PageWrapper = ({ children }) => {
  // const pageVariants = {
  //   initial: {
  //     opacity: 0,
  //   },
  //   in: {
  //     opacity: 1,
  //     transition: { duration: 1, delay: 0.7 },
  //   },
  //   out: {
  //     y: 0,
  //     opacity: 0,
  //     transition: { duration: 0.5, delay: 0 },
  //   },
  // };

  return (
    // <motion.div
    //   initial="initial"
    //   animate="in"
    //   exit="out"
    //   variants={pageVariants}
    //   className={s.wrap}
    // >
    children
    // </motion.div>
  );
};

export default PageWrapper;
