import React, { Suspense, useEffect, useRef } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import history from "./helpers/history";
import slugify from "slugify";
import prerenderedLoadable from "./helpers/prerenderedLoadable";
import ScrollToTop from "./helpers/scrollToTop";
import Navigation from "./components/Navigation";
const storiesData = require(`./pages/Homepage/copy/${process.env.REACT_APP_LANG}`);

const lang = process.env.REACT_APP_LANG;

const storySlugs = storiesData.stories.reduce((obj, next) => {
  obj[next.id] = slugify(next.title).toLowerCase();
  return obj;
}, {});

const Homepage = prerenderedLoadable(() => import("./pages/Homepage"));
const TheReport = prerenderedLoadable(() => import("./pages/TheReport"));
const Media = prerenderedLoadable(() => import("./pages/Media"));
const OnlineCourse = prerenderedLoadable(() => import("./pages/OnlineCourse"));
const DataAndResearch = prerenderedLoadable(() =>
  import("./pages/DataAndResearch")
);
const StoryOne = prerenderedLoadable(() => import("./pages/StoryOne"));
const StoryTwo = prerenderedLoadable(() => import("./pages/StoryTwo"));
const StoryThree = prerenderedLoadable(() => import("./pages/StoryThree"));
const StoryFour = prerenderedLoadable(() => import("./pages/StoryFour"));
const StoryFive = prerenderedLoadable(() => import("./pages/StoryFive"));
const StorySix = prerenderedLoadable(() => import("./pages/StorySix"));
const StorySeven = prerenderedLoadable(() => import("./pages/StorySeven"));
const StoryEight = prerenderedLoadable(() => import("./pages/StoryEight"));
const StoryNine = prerenderedLoadable(() => import("./pages/StoryNine"));
const FourZeroFour = prerenderedLoadable(() => import("./pages/FourZeroFour"));
const ErrorPage = prerenderedLoadable(() => import("./pages/Error"));

const Spotlights = prerenderedLoadable(() => import("./pages/Spotlights"));

const stories = [
  { id: "story-one", path: storySlugs["story-one"], Component: StoryOne },
  { id: "story-two", path: storySlugs["story-two"], Component: StoryTwo },
  { id: "story-three", path: storySlugs["story-three"], Component: StoryThree },
  { id: "story-four", path: storySlugs["story-four"], Component: StoryFour },
  { id: "story-five", path: storySlugs["story-five"], Component: StoryFive },
  { id: "story-six", path: storySlugs["story-six"], Component: StorySix },
  { id: "story-seven", path: storySlugs["story-seven"], Component: StorySeven },
  { id: "story-eight", path: storySlugs["story-eight"], Component: StoryEight },
  { id: "story-nine", path: storySlugs["story-nine"], Component: StoryNine },
];

const Routes = () => {
  const location = useLocation();
  const firstMount = useRef(true);

  const path = location.pathname;

  useEffect(() => {
    if (!firstMount.current) {
      window.wbgData.site.pageLoad = "N";
      window._satellite.track("dynamicdata");
    } else {
      firstMount.current = false;
    }
  }, [path]);

  return (
    <div className="pageWrap">
      <Navigation location={location} />
      <ScrollToTop />

      <Switch location={location} key={location.pathname}>
        {stories.map(({ path, Component }, i) => (
          <Route
            key={path}
            path={`/stories/${path}`}
            render={(props) => <Component {...props} />}
          />
        ))}
        <Route path={`/spotlights`}>
          <Spotlights />
        </Route>
        ))}
        <Route
          exact
          path="/the-report"
          render={(props) => <TheReport {...props} />}
        />
        <Route exact path="/media" render={(props) => <Media {...props} />} />
        <Route
          exact
          path="/online-course"
          render={(props) => <OnlineCourse {...props} />}
        />
        <Route
          exact
          path="/data-and-research"
          render={(props) => <DataAndResearch {...props} />}
        />
        <Route exact path="/" render={(props) => <Homepage {...props} />} />
        <Route path="/error" render={(props) => <ErrorPage {...props} />} />
        <Route path="*" render={(props) => <FourZeroFour {...props} />} />
      </Switch>
    </div>
  );
};

const App = () => (
  <BrowserRouter
    history={history}
    basename={lang !== "EN" ? `/${lang.toLowerCase()}` : null}
  >
    <Suspense fallback={<div>Loading...</div>}>
      <Routes />
    </Suspense>
  </BrowserRouter>
);

export default App;
