import { memo } from "react";
import loadable from "@loadable/component";
import { PrerenderedComponent } from "react-prerendered-component";
import Loader from "../components/Loader";
import PageWrapper from "../components/PageWrapper";

const prerenderedLoadable = (dynamicImport) => {
  const LoadableComponent = loadable(dynamicImport, {
    fallback: <Loader />,
  });
  return memo((props) => (
    <PrerenderedComponent live={LoadableComponent.load()}>
      <PageWrapper>
        <LoadableComponent {...props} />
      </PageWrapper>
    </PrerenderedComponent>
  ));
};

export default prerenderedLoadable;
