import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ delay = 17 }) {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash, delay]);

  // Toggle menu button off
  useEffect(() => {
    const element = document.querySelector(
      '[data-toggle="collapse"][aria-expanded="true"]'
    );

    if (element) {
      element.click();
    }
  }, [pathname]);

  return null;
}
