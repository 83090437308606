import React, { useState, useEffect } from "react";
// import s from "./style.module.css";
import { Link } from "react-router-dom";
import cn from "classnames";
import { createPortal } from "react-dom";

const {
  nav,
} = require(`../../pages/Homepage/copy/${process.env.REACT_APP_LANG}`);

const Navigation = ({ location }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const items = nav.map((d) => ({ ...d, path: d.url }));

  const navList = (
    <ul className="lp__navbar_nav" id="lp__megamenu">
      {items.map(({ path, text }) => (
        <li className="lp__megamenu_list" key={path}>
          <Link
            to={path}
            className={cn("lp__megamenu_item", {
              active: location.pathname === path,
            })}
          >
            {text}
          </Link>
        </li>
      ))}
    </ul>
  );

  const elem = document.getElementById("megamenu");
  if (!mounted) {
    elem.innerHTML = "";
  }

  return createPortal(navList, elem);
};

export default Navigation;
