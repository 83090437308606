import React from "react";
import s from "./style.module.css";
import { ReactComponent as LoaderSVG } from "./loader.svg";

const Loader = () => {
  return (
    <div className={s.wrap}>
      <div className={s.icon}>
        <LoaderSVG />
      </div>
    </div>
  );
};

export default Loader;
